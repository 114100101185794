/*eslint no-magic-numbers: ["error", { "ignore": [16,24] }]*/

import { Typography, pxToRem } from '@affinidi/component-library'
import styled from 'styled-components'

export const ErrorTitle = styled(Typography)`
  margin-bottom: ${pxToRem(16)};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.utility.danger['100']};
`

export const SupportBlock = styled(Typography)`
  margin-top: ${pxToRem(24)};

  & a {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.brand.primary['100']};
  }
`
