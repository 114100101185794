import { SetupCardButton, SetupCardPageLayout, SpinnerPage } from '@affinidi/component-library'
import { FC, ReactElement } from 'react'
import { AboutAffinidiVaultInfoBox } from '../../components/InfoBox/AboutAffinidiVaultInfoBox'

type Props = {
  onLoginWithAppVault: () => void
  isValidating: boolean
}

export const MobileAppLoginUI: FC<Props> = ({ onLoginWithAppVault, isValidating }): ReactElement => {
  return isValidating ? (
    <SpinnerPage text="Validating..." isDark />
  ) : (
    <SetupCardPageLayout
      cardTitle="Welcome to Affinidi Vault"
      cardDescription="Enjoy a seamless login experience with the Affinidi Vault."
    >
      <AboutAffinidiVaultInfoBox />
      <SetupCardButton onClick={onLoginWithAppVault}>Login with Affinidi Vault</SetupCardButton>
    </SetupCardPageLayout>
  )
}
