export enum Keys {
  Backspace = 'Backspace',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Enter = 'Enter',
}

export enum Routes {
  HOME = '/',
  LOGIN = '/login',
  MOBILE_APP = '/mobile-app',
  SIGNUP = '/sign-up',
  CONFIRM_LOGIN = '/login/confirm',
  ERROR = '/error',
}

export enum AuthSteps {
  loginInvited = 'LOGIN_INITIATED',
  loginCompleted = 'LOGIN_COMPLETED',
  ChallengeLoaded = 'CHALLENGE_LOADED',
  NotStarted = 'NOT_STARTED',
  ConsentGiven = 'CONSENT_GIVEN',
}

export enum ResponseType {
  RedirectResponse = 'REDIRECT_RESPONSE',
}

export enum CustomErrors {
  InvalidClient = 'Invalid Client.',
  RequestDeclined = 'Request Declined.',
  UnsupportedBrowser = 'Unsupported Browser.',
  UpdateAffinidiVault = 'Update your Affinidi Vault',
  SessionValidationError = 'Error',
}

export enum CustomErrorDescriptions {
  InvalidClient = 'Invalid Client Credentials. Client authentication failed.',
  RequestDeclined = 'The request of the application to access the data was declined.',
  UnsupportedBrowser = 'The current browser you are using is not supported. For an optimal viewing experience, we recommend using the latest version of Chrome on Windows or macOS.',
  SessionValidationError = 'We had trouble validating your session.',
}

export enum SupportBlock {
  RetryOrRaiseTicket = 'Please try again or raise a ',
  RaiseTicket = 'Please raise a ',
  SupportTicketLink = 'https://share.hsforms.com/1i-4HKZRXSsmENzXtPdIG4g8oa2v',
}

export enum AffinidiInfoBox {
  Title = 'About Affinidi Vault',
  MainText = 'Affinidi Vault enables you to discover, collect, store, share, and monetise your data in a fragmented data world. By leveraging decentralised technologies, Affinidi Vault empowers greater control, adheres to privacy-by-design principles, and prioritises user-centricity at its core.',
  LearnMoreLink = 'https://www.affinidi.com/product/affinidi-vault',
}
