import { Button, SetupCardButton, SetupCardPageLayout, SpinnerPage } from '@affinidi/component-library'
import { ButtonColor } from '@affinidi/component-library/lib/components/Button/Button.themes'
import { FC, ReactElement } from 'react'
import * as S from './ExtensionLogin.styled'
import { ChromeLogoStyled } from '../../components/ChromeLogo/ChromeLogo.styled'
import { AboutAffinidiVaultInfoBox } from '../../components/InfoBox/AboutAffinidiVaultInfoBox'
import config from '../../config'
import { STABLE_AIV_EXTENSION_VERSION } from '../../contexts'
import { Color } from '../../types/colors'
import { CustomErrors } from '../../utils'
import { ErrorWithRetry } from '../Error'

type Props = {
  loading: boolean
  isExtensionInstalled: boolean
  isWebVaultSetUp?: boolean
  isNotOnStableVersion: boolean
  showRetryButton: boolean
  isExpired: boolean
  onRetry: () => void
  onLoginWithExtension: () => void
  onLoginWithWebVault: () => void
}

const STABLE_VERSION_ERROR_DESCRIPTION = `Please install Affinidi Vault (v.${STABLE_AIV_EXTENSION_VERSION} or above) to use it for Affinidi Login`
export const ExtensionLoginUI: FC<Props> = ({
  loading,
  isExtensionInstalled,
  isWebVaultSetUp,
  isNotOnStableVersion,
  showRetryButton,
  isExpired,
  onRetry,
  onLoginWithExtension,
  onLoginWithWebVault,
}): ReactElement => {
  if (!loading && isExtensionInstalled && isNotOnStableVersion) {
    return (
      <SetupCardPageLayout
        cardTitle={CustomErrors.UpdateAffinidiVault.toString()}
        cardDescription={STABLE_VERSION_ERROR_DESCRIPTION}
      >
        <SetupCardButton
          onClick={() => (window.location.href = config.aivExtensionUrl)}
          icon={<ChromeLogoStyled src={'/chrome-logo.png'} />}
          color={Color.SECONDARY as ButtonColor}
        >
          Install Chrome Extension
        </SetupCardButton>
        <S.Divider variant="p2">or</S.Divider>
        <Button onClick={onLoginWithWebVault} fullWidth>
          Login with web app
        </Button>
      </SetupCardPageLayout>
    )
  }

  if (isWebVaultSetUp && isExtensionInstalled) {
    return (
      <SetupCardPageLayout
        cardTitle="Welcome to Affinidi Vault"
        cardDescription="Enjoy a seamless login experience with the Affinidi Vault Web App or Browser Extension."
      >
        <AboutAffinidiVaultInfoBox />
        <SetupCardButton onClick={onLoginWithWebVault}>Login with web app</SetupCardButton>
        <S.Divider variant="p2">or</S.Divider>
        <Button onClick={onLoginWithExtension} color="tertiary" fullWidth>
          Login with Chrome extension
        </Button>
      </SetupCardPageLayout>
    )
  }

  return !isExpired ? (
    <SpinnerPage text="Validating..." isDark />
  ) : (
    <ErrorWithRetry showRetryButton={showRetryButton} retryHandler={onRetry} />
  )
}
