/*eslint no-magic-numbers: ["error", { "ignore": [307] }]*/
import { Api, LoginSessionForIDPInput, LoginSessionForIDPOutput } from './api'
import config from '../../config'

export class VpAdapter {
  constructor(
    private readonly api = new Api({
      baseURL: config.authUrl,
    }),
  ) {}

  async loginSessionForIdp(input: LoginSessionForIDPInput): Promise<LoginSessionForIDPOutput> {
    return this.api.v1.loginSessionForIdp(input)
  }
}

export default new VpAdapter()
