import { InfoBox, Typography } from '@affinidi/component-library'
import { AffinidiInfoBox } from '../../utils'
import { LinkStyled } from '../Link/Link.styled'
import { LinkIconStyled } from '../Link/LinkIcon.styled'

export const AboutAffinidiVaultInfoBox = () => {
  return (
    <InfoBox title={AffinidiInfoBox.Title} type="info" collapsible={true}>
      <Typography variant="p2">
        {AffinidiInfoBox.MainText}

        <a href={AffinidiInfoBox.LearnMoreLink} target="_blank" style={{ display: 'block', marginTop: '24px' }}>
          <LinkStyled variant="l1">Learn more</LinkStyled>
          <LinkIconStyled />
        </a>
      </Typography>
    </InfoBox>
  )
}
