import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useAsync, useAsyncCallback } from 'react-async-hook'
import { MobileAppLoginUI } from './MobileAppLogin.ui'
import { useLoginContext } from '../../contexts'
import vpAdapter from '../../services/vpa'
import { LoginSessionDto } from '../../services/vpa/api'

const getLoginSession = async (loginChallenge: string, clientId: string) =>
  vpAdapter.loginSessionForIdp({ loginChallenge, clientId })

export const MobileAppLogin = (): ReactElement => {
  const { requestVpFromMobileApp } = useLoginContext()

  const [isValidating, setIsValidating] = useState<boolean>(true)
  const [redirectUrl, setRedirectUrl] = useState<string>('')

  const urlParams = new URLSearchParams(window.location.search)
  const loginChallenge = urlParams.get('login_challenge') ?? ''
  const clientId = urlParams.get('client_id') ?? ''

  const loginSession = useAsync(getLoginSession, [loginChallenge, clientId])
  const loginWithAffinidiHandler = useAsyncCallback(
    useCallback(
      async (session: LoginSessionDto) => {
        setIsValidating(false)
        setRedirectUrl(await requestVpFromMobileApp.execute(session))
      },
      [requestVpFromMobileApp.execute],
    ),
  )

  const onLoginWithAppVault = async () => {
    if (redirectUrl) {
      window.location.replace(redirectUrl)
    }
  }

  const error = loginSession.error || loginWithAffinidiHandler.error

  useEffect(() => {
    if (error) {
      throw error
    }
  }, [error])

  useEffect(() => {
    if (loginSession.result) {
      void loginWithAffinidiHandler.execute(loginSession.result)
    }
  }, [loginSession.result])

  return <MobileAppLoginUI onLoginWithAppVault={onLoginWithAppVault} isValidating={isValidating} />
}
