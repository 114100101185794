/*eslint no-magic-numbers: ["error", { "ignore": [1,6] }]*/

import { AivExtensionClient } from '@affinidi/client-aiv-extension'
import { createContext, ReactElement, ReactNode, useCallback, useContext } from 'react'
import { useAsyncCallback } from 'react-async-hook'
import config from '../../config'
import { LoginSessionDto } from '../../services/vpa/api'

const EXTENSION_AUTH_URL = `chrome-extension://${config.aivExtensionId}/login.html`
const WEB_VAULT_URL = `${config.webVaultUrl}/login`
// TODO: we can merge this with WEB_VAULT_URL
const isIOS = /iPad|iPhone|iPod/.test(window.navigator.userAgent)
const MOBILE_APP_AUTH_URL = isIOS ? `${config.webVaultUrl}/mobile-app` : `https://${window.location.host}/mobile-app`

const client = new AivExtensionClient({ chromeExtensionId: config.aivExtensionId })
export const STABLE_AIV_EXTENSION_VERSION = '1.22.0'

const isDirectPostVersion = (version?: string): boolean => {
  if (version) {
    const [majorVer, minorVer] = (version as string).split('.').map(Number)
    if (majorVer > 1 || minorVer >= 6) {
      return true
    }
  }
  return false
}

const requestVp = async (
  { id: loginSessionId, authorizationRequest }: LoginSessionDto,
  authUrl: string,
  applyDirectPost: boolean,
) => {
  const nonce = authorizationRequest?.nonce || 'no-nonce'
  const claims = {
    id_token: {
      email: null,
    },
    vp_token: {
      presentation_definition: JSON.parse(authorizationRequest.presentationDefinition),
    },
  }

  const url = new URL(authUrl)
  url.searchParams.set('response_type', 'vp_token id_token')
  if (authorizationRequest?.clientId) {
    url.searchParams.set('client_id', authorizationRequest.clientId)
  }
  url.searchParams.set('response_mode', 'query')

  if (applyDirectPost) {
    url.searchParams.set('response_mode', 'direct_post')
    url.searchParams.set('accept_response_uri', `${config.authUrl}/v1/login/sessions/${loginSessionId}/accept-response`)
    url.searchParams.set('reject_response_uri', `${config.authUrl}/v1/login/sessions/${loginSessionId}/reject-response`)
    url.searchParams.set('redirect_uri', config.hostUrl)
  }

  url.searchParams.set('scope', 'openid')
  url.searchParams.set('claims', JSON.stringify(claims))
  url.searchParams.set('state', authorizationRequest.state)
  url.searchParams.set('nonce', nonce)

  return url.toString()
}

const useLogin = () => {
  const requestVpFromExtension = useAsyncCallback(
    useCallback(async ({ id, authorizationRequest }: LoginSessionDto) => {
      let version
      try {
        version = await client.getVersion()
      } catch (error) {
        version = undefined
      }

      const applyDirectPost = isDirectPostVersion(version)
      return requestVp({ id, authorizationRequest }, EXTENSION_AUTH_URL, applyDirectPost)
    }, []),
  )

  const requestVpFromWeb = useAsyncCallback(
    useCallback(async ({ id, authorizationRequest }: LoginSessionDto) => {
      return requestVp({ id, authorizationRequest }, WEB_VAULT_URL, true)
    }, []),
  )

  const requestVpFromMobileApp = useAsyncCallback(
    useCallback(async ({ id, authorizationRequest }: LoginSessionDto) => {
      return requestVp({ id, authorizationRequest }, MOBILE_APP_AUTH_URL, true)
    }, []),
  )

  return {
    requestVpFromExtension,
    requestVpFromMobileApp,
    requestVpFromWeb,
  }
}

const LoginContext = createContext({} as ReturnType<typeof useLogin>)

export const LoginContextProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const auth = useLogin()

  return <LoginContext.Provider value={auth}>{children}</LoginContext.Provider>
}

export const useLoginContext = () => {
  return useContext(LoginContext)
}
