import { theme } from '@affinidi/component-library'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { ThemeProvider } from 'styled-components'

import App from './App'
import { ErrorBoundary } from './components/ErrorBoundary'
import { LoginContextProvider } from './contexts'
import './index.scss'

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <LoginContextProvider>
          <App />
        </LoginContextProvider>
      </ErrorBoundary>
    </ThemeProvider>
  </StrictMode>,
)
