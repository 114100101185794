import { SetupCardPageLayout } from '@affinidi/component-library'
import { ReactElement } from 'react'
import * as S from './Home.styled'

export const Home = (): ReactElement => {
  return (
    <SetupCardPageLayout
      cardTitle="Home"
      cardDescription={
        <S.Text>
          <span>Authentication requests to this page are only done through Affinidi Vault services.</span>
          <span>Direct requests to this page will not perform any action.</span>
        </S.Text>
      }
    />
  )
}
