const createConfig = (env: string | undefined) => {
  switch (env) {
    case 'staging':
      throw new Error('staging is not enabled')
    case 'prod':
      return {
        env,
        authUrl: 'https://apse1.api.affinidi.io/vpa',
        hostUrl: 'https://login.affinidi.com',
        aivExtensionId: 'fejpjjkbaklcdcibmkbmpanjbiihclon',
        aivExtensionUrl: 'https://chrome.google.com/webstore/detail/affinidi-vault/fejpjjkbaklcdcibmkbmpanjbiihclon',
        getNotifiedUrl: 'https://www.affinidi.com/product/affinidi-vault/eap',
        webVaultUrl: 'https://vault.affinidi.com',
      }
    case 'dev':
      return {
        env: 'dev',
        authUrl: 'https://apse1.dev.api.affinidi.io/vpa',
        hostUrl: 'https://login.dev.affinidi.com',
        aivExtensionId: 'fejpjjkbaklcdcibmkbmpanjbiihclon',
        aivExtensionUrl: 'https://chrome.google.com/webstore/detail/affinidi-vault/fejpjjkbaklcdcibmkbmpanjbiihclon',
        getNotifiedUrl: 'https://www.affinidi.com/product/affinidi-vault/eap',
        webVaultUrl: localStorage.getItem('webVaultUrl') || 'https://vault.dev.affinidi.com',
      }
    default:
      return {
        env,
        authUrl: 'https://apse1.api.affinidi.io/vpa',
        hostUrl: 'https://login.affinidi.com',
        aivExtensionId: 'fejpjjkbaklcdcibmkbmpanjbiihclon',
        aivExtensionUrl: 'https://chrome.google.com/webstore/detail/affinidi-vault/fejpjjkbaklcdcibmkbmpanjbiihclon',
        getNotifiedUrl: 'https://www.affinidi.com/product/affinidi-vault/eap',
        webVaultUrl: 'https://vault.affinidi.com',
      }
  }
}

export default createConfig(process.env.REACT_APP_ENVIRONMENT)
