import { Typography } from '@affinidi/component-library'
import styled from 'styled-components'

export const Divider = styled(Typography)`
  display: flex;
  align-items: center;
  column-gap: 8px;
  text-transform: uppercase;
  margin-block: 24px;

  &::before,
  &::after {
    content: '';
    flex-grow: 1;
    height: 1px;
    background-color: ${(props) => props.theme.colors.brand.secondary['20']};
  }
`
