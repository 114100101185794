import { SetupCardPageLayout, Typography, TypographyProps, useMediaQuery } from '@affinidi/component-library'
import { ReactElement } from 'react'
import * as S from './Error.styled'
import { LinkStyled } from '../../components/Link/Link.styled'
import { LinkIconStyled } from '../../components/Link/LinkIcon.styled'
import { CustomErrorDescriptions, CustomErrors, SupportBlock } from '../../utils'

type Props = {
  description?: string
  name?: string
}

const extractError = (
  name: string | undefined,
  description: string | undefined,
): { error: string; errorDescription: string } => {
  let error = 'Unknown error.'
  let errorDescription = 'An unidentified error has occurred.'

  if (name) {
    if (
      [
        'LoginConfigurationReadError',
        'LoginConfigurationReadInvalidClientIdError',
        'ChallengeNotFoundError',
        CustomErrors.InvalidClient,
      ].includes(name)
    ) {
      errorDescription = CustomErrorDescriptions.InvalidClient
      error = CustomErrors.InvalidClient
    } else {
      error = 'Unknown error.'
      errorDescription = description ?? errorDescription
    }
  } else {
    const urlParams = new URLSearchParams(window.location.search)
    error = urlParams.get('error') ?? 'Unknown error.'
    errorDescription = urlParams.get('error_description') ?? 'An unidentified error has occurred.'

    // see list of possible errors here https://github.com/ory/fosite/blob/8098e48b2e0935855b61f3d7f924596620fdfc82/errors.go#L225
    // here we can customize errors from the link:
    switch (error) {
      case 'invalid_client':
        errorDescription = CustomErrorDescriptions.InvalidClient
        error = CustomErrors.InvalidClient
        break
      case 'access_denied':
        if (errorDescription === 'request_declined') {
          errorDescription = CustomErrorDescriptions.RequestDeclined
          error = CustomErrors.RequestDeclined
        }
        break
    }
  }
  return { error, errorDescription }
}

export const Error = ({ description, name }: Props): ReactElement => {
  const { getValueByScreenSize } = useMediaQuery()

  const pVariant = getValueByScreenSize<TypographyProps['variant']>({
    default: 'p2',
    desktopXL: 'p1',
  })

  let { error, errorDescription } = extractError(name, description)

  return (
    <SetupCardPageLayout cardTitle="Error">
      <S.ErrorTitle variant={pVariant}>{error}</S.ErrorTitle>
      <Typography variant={pVariant}>{errorDescription}</Typography>

      <S.SupportBlock variant={pVariant}>
        {SupportBlock.RetryOrRaiseTicket}
        <a href={SupportBlock.SupportTicketLink} target="_blank" rel="noopener noreferrer">
          <LinkStyled variant="l1">Support Ticket</LinkStyled>
          <LinkIconStyled />
        </a>
      </S.SupportBlock>
    </SetupCardPageLayout>
  )
}
