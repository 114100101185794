import { SpinnerPage } from '@affinidi/component-library'
import { ReactElement, useEffect } from 'react'
import config from '../../config'

export const MobileApp = (): ReactElement => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const loginUrl = new URL('/login', config.webVaultUrl)
    loginUrl.search = urlParams.toString()
    window.location.replace(loginUrl.toString())
  }, [config.webVaultUrl])

  return <SpinnerPage text="Loading..." isDark />
}
