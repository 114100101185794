import { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Home, ExtensionLogin, MobileAppLogin, Error, MobileApp } from './pages'
import { Routes as RouteName, removeAffinidiDomainAnalyticsCookies } from './utils'

function App() {
  const isMobile = window.navigator.userAgent.includes('Mobile')

  useEffect(() => {
    removeAffinidiDomainAnalyticsCookies()
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path={RouteName.LOGIN} element={isMobile ? <MobileAppLogin /> : <ExtensionLogin />}></Route>
        <Route path={RouteName.MOBILE_APP} element={<MobileApp />}></Route>
        <Route path={RouteName.HOME} element={<Home />}></Route>
        <Route path={RouteName.ERROR} element={<Error />}></Route>
        <Route path="*" element={<Home />}></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
