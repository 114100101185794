import { SVGAttributes } from 'react'

export default (props: SVGAttributes<SVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#012x230lea)">
      <path
        d="M15.833 15.833H4.167V4.167H10V2.5H2.5v15h15V10h-1.667v5.833zM11.667 2.5v1.667h2.991l-8.191 8.191 1.175 1.175 8.191-8.191v2.991H17.5V2.5h-5.833z"
        fill="#1D58FC"
      />
    </g>
    <defs>
      <clipPath id="012x230lea">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)
