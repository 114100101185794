import { AivExtensionClient } from '@affinidi/client-aiv-extension'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useAsync, useAsyncCallback } from 'react-async-hook'
import { ExtensionLoginUI } from './ExtensionLogin.ui'
import config from '../../config'
import { STABLE_AIV_EXTENSION_VERSION, useLoginContext } from '../../contexts'
import vpAdapter from '../../services/vpa'
import { LoginSessionDto } from '../../services/vpa/api'
import { CustomErrorDescriptions, CustomErrors } from '../../utils'
import { CustomError } from '../../utils/errors'

const MAX_LOGIN_RETRIES = 1
const FIRST_TRY_LOGIN_TIMEOUT = 30_000
const DEFAULT_LOGIN_TIMEOUT = 20_000
const client = new AivExtensionClient({ chromeExtensionId: config.aivExtensionId })

const getLoginSession = async (loginChallenge: string, clientId: string) =>
  vpAdapter.loginSessionForIdp({ loginChallenge, clientId })

export const ExtensionLogin = (): ReactElement => {
  const { requestVpFromExtension, requestVpFromWeb } = useLoginContext()

  const urlParams = new URLSearchParams(window.location.search)
  const loginChallenge = urlParams.get('login_challenge') ?? ''
  const clientId = urlParams.get('client_id') ?? ''
  const isExtensionCheckDisabled = urlParams.get('no-extension') === '1'

  const isChromeBrowser = /Chrome/.test(window.navigator.userAgent)

  const isExtensionInstalled = useAsync(() => {
    if (!isChromeBrowser) {
      return Promise.resolve(false)
    }
    if (isExtensionCheckDisabled) {
      return Promise.resolve(true)
    }
    return client.isInstalled()
  }, [])

  const checkStableVersion = useAsync(async () => {
    if (!isChromeBrowser) {
      return Promise.resolve(false)
    }
    if (isExtensionCheckDisabled) {
      return Promise.resolve(true)
    }
    const version = await client.getVersion()
    return !!version && isVersionGreaterOrEqual(version, STABLE_AIV_EXTENSION_VERSION)
  }, [])

  const loginSession = useAsync(async () => {
    if (!clientId || !loginChallenge) {
      throw new CustomError({ name: CustomErrors.InvalidClient, message: CustomErrorDescriptions.InvalidClient })
    }

    return getLoginSession(loginChallenge, clientId)
  }, [loginChallenge, clientId])

  const handleLoginWithExtension = async () => {
    if (loginSession.result) {
      window.location.replace(await requestVpFromExtension.execute(loginSession.result))
    }
  }

  const handleLoginWithWebVault = async () => {
    if (loginSession.result) {
      window.location.replace(await requestVpFromWeb.execute(loginSession.result))
    }
  }

  const loginWithAffinidiHandler = useAsyncCallback(
    useCallback(
      async (session: LoginSessionDto) => {
        if (isExtensionInstalled.loading) return

        const redirectTo = isExtensionInstalled.result
          ? await requestVpFromExtension.execute(session)
          : await requestVpFromWeb.execute(session)

        window.location.replace(redirectTo)
      },
      [requestVpFromExtension.execute, requestVpFromWeb.execute, isExtensionInstalled.status],
    ),
  )

  let loading =
    isExtensionInstalled.loading ||
    loginSession.loading ||
    loginWithAffinidiHandler.loading ||
    checkStableVersion.loading

  const error =
    isExtensionInstalled.error || loginSession.error || loginWithAffinidiHandler.error || checkStableVersion.error

  const [retryCount, setRetryCount] = useState(0)
  const [isExpired, setIsExpired] = useState(false)
  let setTimeoutInstance: any

  const handleRetry = () => {
    setRetryCount((prevCounter) => ++prevCounter)
    setIsExpired(false)
  }

  useEffect(() => {
    if (error) {
      throw error
    }
  }, [error])

  const handleAutoRedirect = useCallback(() => {
    const shouldLoginWithWebVault = !isExtensionInstalled.result
    const shouldLoginWithExtension = isExtensionInstalled.result && checkStableVersion.result
    const shouldLogin = shouldLoginWithWebVault || shouldLoginWithExtension

    if (loginSession.result && shouldLogin && !isExpired) {
      void loginWithAffinidiHandler.execute(loginSession.result)
    }
  }, [isExtensionInstalled.result, loginSession.result, checkStableVersion.result, isExpired])

  useEffect(() => {
    handleAutoRedirect()
  }, [handleAutoRedirect])

  useEffect(() => {
    if (loading && !isExpired) {
      setIsExpired(false)

      if (setTimeoutInstance) {
        clearTimeout(setTimeoutInstance)
      }
      setTimeoutInstance = setTimeout(
        () => {
          setIsExpired(true)
          loading = false
        },
        retryCount < MAX_LOGIN_RETRIES ? FIRST_TRY_LOGIN_TIMEOUT : DEFAULT_LOGIN_TIMEOUT,
      )
    }
  })

  return (
    <ExtensionLoginUI
      loading={loading}
      isExtensionInstalled={isExtensionInstalled.result ?? false}
      onLoginWithExtension={handleLoginWithExtension}
      onLoginWithWebVault={handleLoginWithWebVault}
      isNotOnStableVersion={checkStableVersion.loading ? false : !checkStableVersion.result ?? false}
      showRetryButton={retryCount < MAX_LOGIN_RETRIES}
      onRetry={handleRetry}
      isExpired={isExpired}
    />
  )
}

const isVersionGreaterOrEqual = (version: string, targetVersion: string): boolean => {
  const [majorVer, minorVer] = version.split('.').map(Number)
  const [targetMajorVer, targetMinorVer] = targetVersion.split('.').map(Number)
  if (majorVer > targetMajorVer || (majorVer === targetMajorVer && minorVer >= targetMinorVer)) {
    return true
  }
  return false
}
