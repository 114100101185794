import {
  Box,
  CloseFilledIcon,
  SetupCardButton,
  SetupCardPageLayout,
  TypographyProps,
  useMediaQuery,
} from '@affinidi/component-library'
import { ReactElement } from 'react'
import * as S from './Error.styled'
import { LinkStyled } from '../../components/Link/Link.styled'
import { LinkIconStyled } from '../../components/Link/LinkIcon.styled'
import { CustomErrorDescriptions, CustomErrors, SupportBlock } from '../../utils'

export const ErrorWithRetry = ({
  showRetryButton,
  retryHandler,
}: {
  showRetryButton: boolean
  retryHandler: () => void
}): ReactElement => {
  const { getValueByScreenSize } = useMediaQuery()

  const pVariant = getValueByScreenSize<TypographyProps['variant']>({
    default: 'p2',
    desktopXL: 'p1',
  })

  return (
    <SetupCardPageLayout
      cardTitle={CustomErrors.SessionValidationError}
      cardDescription={CustomErrorDescriptions.SessionValidationError}
      cardTitleComponent={
        <Box style={{ marginBottom: 16 }}>
          <CloseFilledIcon height={32} width={32} />
        </Box>
      }
    >
      <S.SupportBlock variant={pVariant}>
        {showRetryButton ? SupportBlock.RetryOrRaiseTicket : SupportBlock.RaiseTicket}
        <a href={SupportBlock.SupportTicketLink} target="_blank" rel="noopener noreferrer">
          <LinkStyled variant="l1">Support Ticket</LinkStyled>
          <LinkIconStyled />
        </a>
      </S.SupportBlock>

      {showRetryButton && <SetupCardButton onClick={retryHandler}>Retry</SetupCardButton>}
    </SetupCardPageLayout>
  )
}
