import Cookies from 'js-cookie'
export * from './enums'

export function removeAffinidiDomainAnalyticsCookies() {
  Cookies.remove('_ga_H6QLL514TS', { path: '', domain: '.affinidi.com' })
  Cookies.remove('_rdt_uuid', { path: '', domain: '.affinidi.com' })
  Cookies.remove('_ga', { path: '', domain: '.affinidi.com' })
  Cookies.remove('_gcl_au', { path: '', domain: '.affinidi.com' })
  Cookies.remove('_fbp', { path: '', domain: '.affinidi.com' })
  Cookies.remove('_ga_2TCQ2WWJTN', { path: '', domain: '.affinidi.com' })
  Cookies.remove('_ga_SNMDV9QTDS', { path: '', domain: '.affinidi.com' })
  Cookies.remove('_gcl_aw', { path: '', domain: '.affinidi.com' })
  Cookies.remove('__e_inc', { path: '', domain: '.affinidi.com' })
  Cookies.remove('__hstc', { path: '', domain: '.affinidi.com' })
  Cookies.remove('mp_e3cfa50c7c8475b63d560b1bcc01e664_mixpanel', { path: '', domain: '.affinidi.com' })
  Cookies.remove('__hs_opt_out', { path: '', domain: '.affinidi.com' })
  Cookies.remove('hubspotutk', { path: '', domain: '.affinidi.com' })
}
